import React from 'react'

import Media from './Media'
import Metadata from '@components/Metadata'
import Flags from './Flags'
import Screening from './Screening'

import styles from './styles.module.css'

const ContentCard = ({ card }) => {
  return (
    <div className={styles['content-card']}>
      {card.map((content, idx) => (
        <div className={styles['content-details']} key={idx}>
          <Metadata metadata={content.metadata} />
          <Media content={content} />
          <Flags content={content} selectable={content.selectable} />
          <Screening results={content.screening} />
        </div>
      ))}
    </div>
  )
}

export default ContentCard
