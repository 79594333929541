import { useEffect, useRef } from 'react'

const useHighlights = contentItem => {
  const ref = useRef()

  useEffect(() => {
    const ranges = contentItem.flags.map(flag => {
      const range = document.createRange()

      range.setStart(ref.current.firstChild, flag.details.position.start)
      range.setEnd(ref.current.firstChild, flag.details.position.end)

      return range
    })

    CSS.highlights.set('flags', new Highlight(...ranges))
  }, [contentItem.flags])

  return { ref }
}

export default useHighlights
