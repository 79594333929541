import React from 'react'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'
import { Dialog } from '@cvg/react-components'

import ArrowIcon from '@components/Icons/Arrow'
import UserStats from '@components/UserStats'

import styles from '../styles.module.css'

// TODO close dots menu when this opens?
const MyStats = () => {
  const { getTranslation } = useLanguageContext()
  const { currentUser } = useCustomContext()

  if (!currentUser.userId) return

  return (
    <div className={styles.setting}>
      <div>
        <strong>{getTranslation('My Stats')}</strong>
        <p>{getTranslation('Celebrate your contributions!')}</p>
      </div>

      <Dialog>
        <Dialog.Open>
          {getTranslation('Go')}
          <ArrowIcon />
        </Dialog.Open>

        <Dialog.Container>
          <UserStats title='My Stats' userId={currentUser.userId} />
          <Dialog.Close>{getTranslation('Close')}</Dialog.Close>
        </Dialog.Container>
      </Dialog>
    </div>
  )
}

export default MyStats
