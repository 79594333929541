import React from 'react'

import { MEDIA_TYPES } from '@utils/constants'

import Photo from './Photo'
import Text from './Text'
import Video from './Video'
import VideoTranscription from './VideoTranscription'

const MediaComponents = {
  [MEDIA_TYPES.image]: Photo,
  [MEDIA_TYPES.text]: Text,
  [MEDIA_TYPES.video]: Video,
  [MEDIA_TYPES.videoTranscription]: VideoTranscription
}

const Media = ({ data, mediaType }) => {
  const MediaComponent = MediaComponents[mediaType]

  if (!MediaComponent) return

  return <MediaComponent data={data} />
}

export default Media
