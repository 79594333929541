import React from 'react'
import PropTypes from 'prop-types'

import { useLanguageContext } from '@cvg/react-hooks'

import { ModalButtons } from '@cvg/react-components'
const { PhotoModalButton } = ModalButtons

import FlagContent from '@components/ContentCard/FlagContent'

import styles from './styles.module.css'

const Photo = ({ content }) => {
  const { getTranslation } = useLanguageContext()

  return (
    <div className={styles.photos}>
      <h3>{getTranslation(content.label)}</h3>
      <ul data-count={content.contentItems.length}>
        {content.contentItems.map(contentItem => (
          <li key={contentItem.id}>
            <PhotoModalButton photo={contentItem.data}>
              <img alt={getTranslation('Photo to review')} src={contentItem.data} />
            </PhotoModalButton>
            <FlagContent contentItem={contentItem} />
          </li>
        ))}
      </ul>
    </div>
  )
}

Photo.propTypes = {
  content: PropTypes.object.isRequired
}

export default Photo
