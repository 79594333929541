import { VALIDATIONS } from '@utils/validations'
import { CONTEXT_GROUP_BY, STEP_ACTIONS } from '../../constants'
import { rejectionReasons } from '@cvg/content-review-localizations'

const { required } = VALIDATIONS

const reviewSteps = [
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Review Elisha Bible Study',
    captionShort: 'Elisha',
    contentCardsConfig: [
      [
        {
          label: 'Elisha Bible Study Reflection',
          contentFilters: { role: 'elishaBibleStudy-reflection' },
          validations: [required],
          validFlagReasons: [
            rejectionReasons.doesNotMeetExpectations,
            rejectionReasons.incomplete,
            rejectionReasons.inappropriateContent
          ]
        },
        {
          label: 'Elisha Bible Study Participant Count',
          contentFilters: { role: 'elishaBibleStudy-participantCount' },
          validFlagReasons: [rejectionReasons.doesNotMeetExpectations, rejectionReasons.incomplete],
          validations: [required]
        }
      ]
    ]
  },
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Review Talents Bible Study',
    captionShort: 'Talents',
    contentCardsConfig: [
      [
        {
          label: 'Talents Bible Study Reflection',
          contentFilters: { role: 'talentsBibleStudy-reflection' },
          validations: [required],
          validFlagReasons: [
            rejectionReasons.doesNotMeetExpectations,
            rejectionReasons.incomplete,
            rejectionReasons.inappropriateContent
          ]
        },
        {
          label: 'Talents Bible Study Participant Count',
          contentFilters: { role: 'talentsBibleStudy-participantCount' },
          validFlagReasons: [rejectionReasons.doesNotMeetExpectations, rejectionReasons.incomplete],
          validations: [required]
        }
      ]
    ]
  },
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Review 5000 Bible Study',
    captionShort: '5000',
    contentCardsConfig: [
      [
        {
          label: 'Feeding 5000 Bible Study Reflection',
          contentFilters: { role: 'feeding5000BibleStudy-reflection' },
          validations: [required],
          validFlagReasons: [
            rejectionReasons.doesNotMeetExpectations,
            rejectionReasons.incomplete,
            rejectionReasons.inappropriateContent
          ]
        },
        {
          label: 'Feeding 5000 Bible Study Participant Count',
          contentFilters: { role: 'feeding5000BibleStudy-participantCount' },
          validFlagReasons: [rejectionReasons.doesNotMeetExpectations, rejectionReasons.incomplete],
          validations: [required]
        }
      ]
    ]
  },
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Review Church Vision Statement',
    captionShort: 'Vision',
    contentCardsConfig: [
      [
        {
          label: 'Church Vision Statement',
          contentFilters: { role: 'churchVisionStatement-visionStatement' },
          validFlagReasons: [
            rejectionReasons.doesNotMeetExpectations,
            rejectionReasons.incomplete,
            rejectionReasons.inappropriateContent
          ],
          validations: [required]
        }
      ],
      [
        {
          label: 'Church Profile Photo',
          contentFilters: { role: 'churchVisionStatement-profileImageUrl' },
          validFlagReasons: [rejectionReasons.photoExcessivelyBlurry, rejectionReasons.inappropriateContent]
        }
      ]
    ]
  },
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Review Community Mapping',
    captionShort: 'Community',
    contentCardsConfig: [
      [
        {
          label: 'Community Mapping Photos',
          contentFilters: { role: 'communityMapping-media' },
          validFlagReasons: [
            rejectionReasons.doesNotMeetExpectations,
            rejectionReasons.photoExcessivelyBlurry,
            rejectionReasons.inappropriateContent
          ]
        },
        {
          label: 'Community Mapping Participant Count',
          contentFilters: { role: 'communityMapping-participantCount' },
          validFlagReasons: [rejectionReasons.doesNotMeetExpectations, rejectionReasons.incomplete],
          validations: [required]
        }
      ]
    ]
  },
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Review Seed Ranking',
    captionShort: 'Seed',
    contentCardsConfig: [
      [
        {
          label: 'Seed Ranking Photos',
          contentFilters: { role: 'seedRanking-media' },
          validFlagReasons: [
            rejectionReasons.doesNotMeetExpectations,
            rejectionReasons.photoExcessivelyBlurry,
            rejectionReasons.inappropriateContent
          ]
        },
        {
          label: 'Seed Ranking Participant Count',
          contentFilters: { role: 'seedRanking-participantCount' },
          validFlagReasons: [rejectionReasons.doesNotMeetExpectations, rejectionReasons.incomplete],
          validations: [required]
        }
      ]
    ]
  },
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Review Resource Square',
    captionShort: 'Resource',
    contentCardsConfig: [
      [
        {
          label: 'Resource Square Photos',
          contentFilters: { role: 'resourceSquare-media' },
          validFlagReasons: [
            rejectionReasons.doesNotMeetExpectations,
            rejectionReasons.photoExcessivelyBlurry,
            rejectionReasons.inappropriateContent
          ]
        },
        {
          label: 'Resource Square Participant Count',
          contentFilters: { role: 'resourceSquare-participantCount' },
          validFlagReasons: [rejectionReasons.doesNotMeetExpectations, rejectionReasons.incomplete],
          validations: [required]
        }
      ]
    ]
  },
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Review Minibus Plan',
    captionShort: 'Minibus',
    contentCardsConfig: [
      [
        {
          label: 'Minibus Plan Photos',
          contentFilters: { role: 'minibusPlan-media' },
          validFlagReasons: [
            rejectionReasons.doesNotMeetExpectations,
            rejectionReasons.photoExcessivelyBlurry,
            rejectionReasons.inappropriateContent
          ]
        },
        {
          label: 'Minibus Plan Participant Count',
          contentFilters: { role: 'minibusPlan-participantCount' },
          validFlagReasons: [rejectionReasons.doesNotMeetExpectations, rejectionReasons.incomplete],
          validations: [required]
        }
      ]
    ]
  },
  { action: STEP_ACTIONS.submit, captionLong: 'Submit', captionShort: 'Submit' }
]

export default { reviewSteps, sortBy: { groupBy: CONTEXT_GROUP_BY.causes, sortOrder: 1 } }
