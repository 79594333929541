import React, { useState } from 'react'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'
import { TRANSLATION_TYPES } from '@utils/constants'

import Tabs from './Tabs'
import Text from './Text'
import Error from '@components/Error'

import styles from './styles.module.css'

const Translation = ({ content, languages }) => {
  const [tabView, setTabView] = useState(TRANSLATION_TYPES.source)

  const { getTranslation } = useLanguageContext()
  const { currentStep } = useCustomContext()

  const hasError = content.contentItems.some(contentItem => Object.hasOwn(currentStep.errors, contentItem.id))

  return (
    <div className={styles.translation} data-view={tabView}>
      <h3>{getTranslation(content.label)}</h3>
      <Tabs view={tabView} languages={languages} setView={setTabView} />
      <div className={styles.translations}>
        <Text contentItems={content.contentItems} type={TRANSLATION_TYPES.source} />
        <Text contentItems={content.contentItems} type={TRANSLATION_TYPES.target} />
      </div>

      {hasError && <Error error={getTranslation('TRANSLATION_ERROR')} />}
    </div>
  )
}

export default Translation
