import React from 'react'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import PlayIcon from '@components/Icons/Play'
import PauseIcon from '@components/Icons/Pause'
import TimeInput from './TimeInput'

import styles from './styles.module.css'

const Timestamps = ({ subtitleId, startTime, endTime, isPlaying, isPaused, play, pause, resume, transcription }) => {
  const { updateContent } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  const handleOnChange = ({ name, value }) => {
    const [id, type] = name.split('-')

    const data = transcription.data.translation?.source.data || transcription.data
    const updatedData = data.map(data => (data.id === id ? { ...data, [type]: value } : data))

    const eventTarget = {
      name: `timestamp_${transcription.id}`,
      value: transcription.data.translation
        ? {
            translation: {
              target: { ...transcription.data.translation.target, data: [] },
              source: { ...transcription.data.translation.source, data: updatedData }
            }
          }
        : updatedData
    }

    updateContent(eventTarget)
  }

  return (
    <div className={styles.timestamps} data-playing={isPlaying}>
      <TimeInput
        name={`${subtitleId}-startTime`}
        label={getTranslation('Starts')}
        onChange={e => handleOnChange(e.target)}
        readOnly={transcription.readOnly}
        value={startTime}
      />
      <TimeInput
        label={getTranslation('Ends')}
        name={`${subtitleId}-endTime`}
        onChange={e => handleOnChange(e.target)}
        readOnly={transcription.readOnly}
        value={endTime}
      />

      <button onClick={isPaused ? resume : isPlaying ? pause : play}>
        {isPlaying && !isPaused ? <PauseIcon /> : <PlayIcon />}
      </button>
    </div>
  )
}

export default Timestamps
