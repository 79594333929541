export const DEFAULT_UPSERT_USER_ERROR_MESSAGE = 'Failed to update user.'
export const ERROR_STATUS_ALREADY_REVIEWED = 404
export const ERROR_STATUS_UPSERT_USER_FORBIDDEN = 403
export const MAX_DISPLAYED_COUNTRIES = 2
export const MAX_DISPLAYED_LANGUAGES = 3
export const REVIEWS_QUERY_LIMIT = 50
export const STATS_DATE_MIN = '2020-01-01'

export const CONTEXT_GROUP_BY = Object.freeze({
  causes: 'causes',
  community: 'community'
})

export const CONTEXT_TYPES = Object.freeze({
  causeApproval: 'n2n-cause-approval',
  causeSupporterLocalization: 'n2n-cause-supporter-localization',
  causeProgressApproval: 'n2n-progress-update-approval',
  clipsTranscription: 'clips-transcription',
  clipsTranslation: 'clips-translation',
  communityComment: 'community-comment',
  communityPost: 'community-post',
  communityProfile: 'community-profile',
  foundations: 'n2n-foundations',
  foundationsSupporterLocalization: 'n2n-foundations-supporter-localization',
  patch: 'patch'
})

export const MEDIA_TYPES = Object.freeze({
  image: 'image',
  text: 'text',
  video: 'video',
  videoTranscription: 'transcription',
  videoTranslation: 'videoTranslation'
})

export const METADATA_TYPES = Object.freeze({
  api: 'api',
  photo: 'image',
  link: 'link',
  text: 'text'
})

export const INPUT_TYPES = Object.freeze({
  flexTextarea: 'flexTextarea',
  select: 'select'
})

export const RECORD_STATUS = Object.freeze({
  isBeingReviewed: 'Record is currently being moderated.'
})

export const RECORD_STATUS_TYPES = Object.freeze({
  info: 'info'
})

export const STEP_ACTIONS = Object.freeze({
  review: 'review',
  submit: 'submit',
  noConfig: 'noConfig'
})

export const SELECT_ACTIONS = Object.freeze({
  select: 'select',
  unselect: 'unselect'
})

// eslint-disable-next-line prettier/prettier
export const SUPPORTED_REVIEW_COUNTRIES = [ 'AU', 'BD', 'BF', 'BO', 'BR', 'CA', 'CH', 'CO', 'DE', 'DO', 'EC', 'ES', 'ET', 'FR', 'GB', 'GH', 'GT', 'HN', 'HT', 'ID', 'IE', 'IT', 'KE', 'KH', 'KR', 'LK', 'MM', 'MW', 'MX', 'NI', 'NL', 'NO', 'NZ', 'PE', 'PH', 'RW', 'SE', 'SV', 'TG', 'TH', 'TZ', 'UG', 'US', 'ZA', 'ZM' ]

// eslint-disable-next-line prettier/prettier
export const SUPPORTED_REVIEW_LANGUAGES = ['am', 'bn', 'da', 'de', 'en', 'es', 'fi', 'fil', 'fr', 'id', 'is', 'it', 'ko', 'my', 'nl', 'no', 'om', 'pt', 'qu', 'rw', 'si', 'sv', 'sw', 'ta', 'th', 'tl']

export const TAG_PRIORITIES = Object.freeze({
  fcp: 1,
  supporter: 2,
  photo: 3,
  photos: 4,
  video: 5
})

export const TRANSLATION_TYPES = Object.freeze({
  source: 'source',
  target: 'target'
})

export const USER_MODES = Object.freeze({
  addUser: 'Add User',
  updateUser: 'Update User'
})

export const USER_ROLES = Object.freeze({
  admin: 'admin',
  owner: 'owner',
  reviewer: 'reviewer'
})
