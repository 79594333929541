import React from 'react'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import { isOverlappingPreviousFlag } from '@utils/flags'

import styles from './styles.module.css'

const FlagContent = ({ contentItem, flaggedContent = {}, ...rest }) => {
  const { setOverlappingFlag, setFlag } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  const flagContent = () => {
    const setter = isOverlappingPreviousFlag(contentItem.flags, flaggedContent) ? setOverlappingFlag : setFlag

    setter({ contentItemId: contentItem.id, flaggedContent })
  }

  return (
    <button
      aria-label={getTranslation('Flag Content')}
      className={styles.flag}
      data-flagged={!!contentItem.flags.length}
      onClick={flagContent}
      {...rest}
    >
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
        <path d='M14.4 6L14 4H5v17h2v-7h5.6l.4 2h7V6z' />
      </svg>
    </button>
  )
}

export default FlagContent
