import { useEffect, useState } from 'react'

const useSelection = contentItem => {
  const [selection, setSelection] = useState()

  // set user selection
  useEffect(() => {
    const callback = e => {
      if (!e.target.hasAttribute('data-selectable')) return

      const range = document.getSelection().getRangeAt(0)

      // correct 3 clicks range ( resolves 0 0 in Chrome )
      if (e.detail === 3) range.setEnd(e.target.firstChild, e.target.innerText.length)

      // ignore 0 ranges
      if (range.endOffset - range.startOffset) {
        setSelection({
          matches: [contentItem.data.slice(range.startOffset, range.endOffset)],
          details: {
            position: {
              end: range.endOffset,
              start: range.startOffset
            }
          }
        })
      }
    }

    document.addEventListener('mouseup', callback)

    return () => document.removeEventListener('mouseup', callback)
  }, [])

  // clear user selection
  useEffect(() => {
    const callback = e => {
      // handle ignorable clicks
      if (e.target.closest('dialog') || e.target.closest('[data-flagged]')) return

      // Nuke previous selection from memory ( fix clicks in within the previous selection )
      document.getSelection().empty()
      setSelection(undefined)
    }

    document.addEventListener('mousedown', callback)

    return () => document.removeEventListener('mousedown', callback)
  }, [])

  return { selection }
}

export default useSelection
