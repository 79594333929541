import React from 'react'

import FlaggedContent from './FlaggedContent'

import { useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const Flags = ({ content, selectable }) => {
  const { getTranslation } = useLanguageContext()

  const hasFlaggedContent = !!content.contentItems.reduce((flagCount, { flags }) => flagCount + flags.length, 0)

  if (!hasFlaggedContent) return

  return (
    <div className={styles.flags}>
      <h2>{getTranslation(selectable ? 'Flagged Content' : 'Flag Reasons')}</h2>

      <FlaggedContent content={content} selectable={selectable} />
    </div>
  )
}

export default Flags
