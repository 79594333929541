import React from 'react'

import { VideoComponents } from '@cvg/react-components'
import { useLanguageContext } from '@cvg/react-hooks'

import FlagContent from '@components/ContentCard/FlagContent'

import styles from './styles.module.css'

const { VideoPlayer, VIDEO_PROVIDER } = VideoComponents

const Video = ({ content }) => {
  const { getTranslation } = useLanguageContext()

  return (
    <div className={styles.videos}>
      <h3>{getTranslation(content.label)}</h3>
      <ul>
        {content.contentItems.map(contentItem => (
          <li key={contentItem.id}>
            <VideoPlayer id='video' videoType={VIDEO_PROVIDER.url} videoUrl={contentItem.data} />
            <FlagContent contentItem={contentItem} />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Video
