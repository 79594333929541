import React from 'react'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import { TRANSLATION_TYPES } from '@utils/constants'

import AddIcon from '@components/Icons/Add'
import styles from './styles.module.css'

const AddSubtitle = ({ transcription }) => {
  const { getTranslation } = useLanguageContext()
  const { updateContent } = useCustomContext()

  const addSubtitle = () => {
    const data = transcription.data.translation?.source.data || transcription.data

    const { id: lastId = '0', endTime: lastEndTime = 0 } = data[data.length - 1] || {}

    const newSubtitle = {
      id: (parseInt(lastId) + 1).toString(),
      startTime: lastEndTime,
      endTime: lastEndTime + 1000,
      text: ''
    }

    const eventTarget = {
      name: `${TRANSLATION_TYPES.source}_${transcription.id}`,
      value: transcription.data.translation
        ? {
            translation: {
              source: { ...transcription.data.translation.source, data: data.concat([newSubtitle]) },
              target: { ...transcription.data.translation.target, data: [] }
            }
          }
        : data.concat([newSubtitle])
    }

    updateContent(eventTarget)
  }

  if (transcription.readOnly) return null

  return (
    <button aria-label={getTranslation('Add Subtitle')} className={styles.add} onClick={addSubtitle} type='button'>
      <span />
      <span />
      <div />
      <AddIcon />
    </button>
  )
}

export default AddSubtitle
