import React from 'react'
import dayjs from 'dayjs'

import { useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const DateFilter = ({ dates, onChange, min, max, ...rest }) => {
  const { getTranslation } = useLanguageContext()

  return (
    <form className={styles.dates}>
      {Object.entries(dates).map(([key, value], idx) => (
        <label className={styles.date} key={key} htmlFor={key}>
          {getTranslation(key)}
          <input
            type='date'
            id={key}
            name={key}
            // prevent crossover
            min={idx === 1 ? Object.values(dates)[0] : min}
            max={idx === 0 ? Object.values(dates)[1] || max : max}
            onChange={onChange}
            value={value || dayjs().format('YYYY-MM-DD')}
            {...rest}
          />
        </label>
      ))}
    </form>
  )
}

export default DateFilter
