import React from 'react'

import { Input } from '@cvg/react-components'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import FlagContent from '../../FlagContent'

import styles from './styles.module.css'

const Textarea = ({ content }) => {
  const { currentStep, updateContent } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  const handleOnChange = ({ name, value }, contentItem) => {
    const eventTarget = {
      name,
      value: contentItem.data.translation
        ? {
            translation: {
              source: { ...contentItem.data.translation.source, data: value },
              target: { ...contentItem.data.translation.target, data: value }
            }
          }
        : value
    }

    updateContent(eventTarget)
  }

  return (
    <div className={styles.textarea}>
      <h3>{getTranslation(content.label)}</h3>
      <ul>
        {content.contentItems.map(contentItem => (
          <li key={contentItem.id}>
            <Input
              aria-label={content.label}
              error={currentStep.errors[contentItem.id]}
              onChange={e => handleOnChange(e.target, contentItem)}
              name={contentItem.id}
              readOnly={contentItem.readOnly}
              title={contentItem.readOnly ? getTranslation('This value may not be edited') : ''}
              type='flexTextarea'
              value={contentItem.data.translation?.source?.data || contentItem.data}
            />
            <FlagContent contentItem={contentItem} />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Textarea
