import React from 'react'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'
import { Dialog, Input } from '@cvg/react-components'

import { getContentItemFromStep, isEqual } from '@utils/helpers'
import { isOverlap } from '@utils/flags'
import { localizeArray } from '@utils/localization'

import styles from './styles.module.css'

const FlagDialog = () => {
  const { currentStep, flag, setFlag, flagContent } = useCustomContext()
  const { getTranslation } = useLanguageContext()
  const { contentItemId, flaggedContent = {} } = flag || {}

  if (!flag) return

  const contentItem = getContentItemFromStep(contentItemId, currentStep)

  const selectReasons = reasons => {
    // Filter out the previous overlapping flag object so an updated one can be created below ( if needed )
    // The .details check ensures non-selectable content works here
    const flags = contentItem.flags.filter(previousFlag => previousFlag.details && !isOverlap(previousFlag, flaggedContent))

    if (reasons.length) flags.push({ ...flaggedContent, reasons })

    flagContent(contentItemId, flags, currentStep)
  }

  const options = new Map(
    [...localizeArray(contentItem.validFlagReasons, getTranslation).entries()].sort((a, b) => a[1].localeCompare(b[1]))
  )

  return (
    <Dialog isOpen={true} onClose={setFlag}>
      <Dialog.Container className={styles['flag-dialog']}>
        <Dialog.Content>
          {flaggedContent.matches && (
            <fieldset>
              <legend>{getTranslation('Flagged Content')}</legend>
              <ul>
                {flaggedContent.matches.map(match => (
                  <li key={match}>{match}</li>
                ))}
              </ul>
            </fieldset>
          )}

          <fieldset>
            <legend>{getTranslation('Reasons')}</legend>
            <Input
              className={styles.reasons}
              name='flags'
              type='multiselect'
              options={options}
              onSelect={selectReasons}
              selected={contentItem.flags.find(flag => isEqual(flag.details, flaggedContent.details))?.reasons || []}
            />
          </fieldset>

          <Dialog.Close>{getTranslation('Close')}</Dialog.Close>
        </Dialog.Content>
      </Dialog.Container>
    </Dialog>
  )
}

export default FlagDialog
