import React from 'react'
import PropTypes from 'prop-types'

import { useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const FlagReasons = ({ reasons }) => {
  const { getTranslation } = useLanguageContext()

  return (
    <ul className={styles.reasons}>
      {reasons.map((reason, idx) => (
        <li key={idx}>{getTranslation(reason)}</li>
      ))}
    </ul>
  )
}

FlagReasons.propTypes = {
  reasons: PropTypes.array.isRequired
}

export default FlagReasons
