import React from 'react'

import { useCustomContext } from '@cvg/react-hooks'

import FlagReasons from '../FlagReasons'

import styles from './styles.module.css'

const FlaggedContent = ({ content, selectable }) => {
  const { setFlag } = useCustomContext()

  if (!selectable) {
    const reasons = content.contentItems.flatMap(item => item.flags).flatMap(flags => flags.reasons)

    return <FlagReasons reasons={reasons} />
  }

  return (
    <ul className={styles['flagged-content']}>
      {content.contentItems.flatMap(({ id, flags }) => {
        return flags.map((flag, idx) => (
          <li key={idx}>
            <button onClick={() => setFlag({ contentItemId: id, flaggedContent: flag })}>
              <p>{flag.matches[0]}</p>
              {/* This div facilitates container queries */}
              <div>
                <FlagReasons reasons={flag.reasons} />
              </div>
            </button>
          </li>
        ))
      })}
    </ul>
  )
}

export default FlaggedContent
