import React from 'react'
import { MEDIA_TYPES } from '@utils/constants'
import { getTranslationLanguages } from '@utils/localization'

import Step from '@components/Step'
import Photo from './Photo'
import Select from './Select'
import SelectableText from './SelectableText'
import Textarea from './Textarea'
import Translation from './Translation'
import Video from './Video'
import VideoTranscription from './VideoTranscription'
import VideoTranslation from './VideoTranslation'

const Media = ({ content }) => {
  const languages = getTranslationLanguages(content)

  if (content.mediaType === MEDIA_TYPES.text) {
    if (languages.source !== languages.target) {
      return <Translation content={content} languages={languages} />
    } else if (content.contentItems.every(({ allowedValues }) => allowedValues?.length > 0)) {
      return <Select content={content} />
    } else if (content.selectable) {
      return <SelectableText content={content} />
    } else return <Textarea content={content} />
  }

  if (content.mediaType === MEDIA_TYPES.image) {
    return <Photo content={content} />
  }

  if (content.mediaType === MEDIA_TYPES.video) {
    return <Video content={content} />
  }

  if (content.mediaType === MEDIA_TYPES.videoTranscription) {
    return <VideoTranscription content={content} />
  }

  if (content.mediaType === MEDIA_TYPES.videoTranslation) {
    return <VideoTranslation content={content} languages={languages} />
  }

  return <Step.Error />
}

export default Media
