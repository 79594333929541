import React from 'react'

import { useLanguageContext } from '@cvg/react-hooks'

import { TRANSLATION_TYPES } from '@utils/constants'

import styles from './styles.module.css'

const Tabs = ({ languages, setView, view }) => {
  const { getTranslation } = useLanguageContext()

  return (
    <div className={styles.tabs} data-view={view}>
      <div className={styles.buttons}>
        <button data-source onClick={() => setView(TRANSLATION_TYPES.source)}>
          {getTranslation(languages.source)}
        </button>
        <button data-target onClick={() => setView(TRANSLATION_TYPES.target)}>
          {getTranslation(languages.target)}
        </button>
      </div>
      <div className={styles.slider} />
      <div className={styles.bg} />
    </div>
  )
}

export default Tabs
