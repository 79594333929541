import React from 'react'

import useReviewData from './useReviewData'

import HelpText from '@components/HelpText'
import Page from '@components/Page'
import Steps from '@components/Steps'
import SubHeader from './SubHeader'
import FlagDialog from '@components/FlagDialog'
import FlagConfirmDialog from '@components/FlagConfirmDialog'

import styles from './styles.module.css'

const Review = () => {
  const { fetchError, isLoading, ...rest } = useReviewData()

  return (
    <Page className={styles.review}>
      <Page.Header />
      <Page.ContextProvider {...rest}>
        <Page.AsyncContent isError={!!fetchError} isLoading={isLoading}>
          <SubHeader />
          <Page.Main style={styles.main}>
            <HelpText />
            <Steps />
            <FlagDialog />
            <FlagConfirmDialog />
          </Page.Main>
        </Page.AsyncContent>
      </Page.ContextProvider>
    </Page>
  )
}

export default Review
