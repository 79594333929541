import React from 'react'
import { useSWRConfig } from 'swr'

import { Input } from '@cvg/react-components'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import { getSortedContexts } from '@utils/config'

import RefreshIcon from '@components/Icons/Refresh'
import Page from '@components/Page'
import ContextCard from '@components/ContextCard'

import { useSearchContext } from '@components/AppProviders/Search'

import styles from './styles.module.css'

const Home = () => {
  const { setSearch } = useSearchContext()

  const { isCurrentUserLoading, currentUser, currentUserError } = useCustomContext()
  const { getTranslation } = useLanguageContext()
  const { mutate } = useSWRConfig()

  const { contexts } = currentUser

  return (
    <Page className={styles.home}>
      <Page.Header />
      <Page.Main>
        <div className={styles.subheader}>
          <h2>{getTranslation('My Queue')}</h2>
          {/* TODO search filter in filters dir? */}
          <Input name='search' onSubmit={setSearch} placeholder={getTranslation('Enter search criteria')} type='search' />
          <button className={styles.refresh} onClick={() => mutate(() => true, undefined)}>
            <RefreshIcon />
            {getTranslation('Refresh')}
          </button>
        </div>

        <Page.AsyncContent isError={!!currentUserError} isLoading={isCurrentUserLoading}>
          <div className={styles.contexts} data-contexts={contexts.length} data-empty={getTranslation('NO_PERMISSIONS')}>
            {getSortedContexts(contexts).map(context => (
              <ContextCard context={context} key={context} />
            ))}
          </div>
        </Page.AsyncContent>
      </Page.Main>
    </Page>
  )
}

export default Home
