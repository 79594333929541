import React from 'react'
import { useLanguageContext } from '@cvg/react-hooks'

const UnsupportedBrowserFallback = ({ children }) => {
  const { getTranslation } = useLanguageContext()

  if (navigator.userAgent.includes('Firefox')) {
    return <p>{getTranslation('SELECTABLE_CONTENT_FIREFOX_WARNING')}</p>
  }

  return children
}

export default UnsupportedBrowserFallback
