import React from 'react'

import { Input } from '@cvg/react-components'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const Text = ({ contentItems, type }) => {
  const { getTranslation } = useLanguageContext()
  const { currentStep, updateContent } = useCustomContext()

  const updateTranslation = (contentItem, { name, value }) => {
    const eventTarget = {
      name: `${type}_${contentItem.id}`,
      value: {
        translation: {
          ...contentItem.data.translation,
          [type]: {
            ...contentItem.data.translation[type],
            data: contentItem.data.translation[type].data.map(dataItem =>
              `${type}_${dataItem.id}` === name ? { ...dataItem, text: value } : dataItem
            )
          }
        }
      }
    }

    updateContent(eventTarget)
  }

  return (
    <ul className={styles[type]}>
      {contentItems.map(contentItem => {
        return contentItem.data.translation[type].data.map(data => (
          <li key={data.id}>
            <Input
              aria-labelledby={`${type}_language`}
              error={currentStep.errors[contentItem.id]}
              name={`${type}_${data.id}`}
              onChange={e => updateTranslation(contentItem, e.target)}
              readOnly={contentItem.data.translation[type].readOnly}
              title={contentItem.data.translation[type].readOnly ? getTranslation('This value may not be edited') : ''}
              type='flexTextarea'
              value={data.text}
            />
          </li>
        ))
      })}
    </ul>
  )
}

export default Text
