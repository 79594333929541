import React from 'react'
import { Dialog } from '@cvg/react-components'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const FlagConfirmDialog = () => {
  const { getTranslation } = useLanguageContext()
  const { overlappingFlag, setOverlappingFlag, setFlag } = useCustomContext()

  const onConfirm = () => {
    setFlag(overlappingFlag)
    setOverlappingFlag()
  }

  return (
    <Dialog isOpen={!!overlappingFlag} onClose={setOverlappingFlag}>
      <Dialog.Container className={styles['flag-confirm-dialog']}>
        <Dialog.Content>
          <p>{getTranslation('FLAG_OVERLAP_WARNING')}</p>
        </Dialog.Content>
        <Dialog.Content>
          <Dialog.Close>{getTranslation('Cancel')}</Dialog.Close>
          <button onClick={onConfirm}>{getTranslation('Replace Flag')}</button>
        </Dialog.Content>
      </Dialog.Container>
    </Dialog>
  )
}

export default FlagConfirmDialog
