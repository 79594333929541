import React from 'react'
import PropTypes from 'prop-types'

import Media from './Media'
import FlagReasons from './FlagReasons'

import styles from './styles.module.css'

const FlaggedContentItems = ({ contentItems }) => {
  return (
    <div className={styles['flagged-items']}>
      {contentItems
        .filter(item => item.flags.length)
        .flatMap((item, flatIdx) => {
          return item.flags.map((flag, idx) => {
            return (
              <div className={styles['flagged-item']} key={`flagged_${flatIdx}_${idx}`}>
                <Media data={flag.matches?.[0] || item.data} mediaType={item.mediaType} />
                <FlagReasons reasons={flag.reasons} />
              </div>
            )
          })
        })}
    </div>
  )
}

FlaggedContentItems.propTypes = {
  contentItems: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default FlaggedContentItems
