import { MEDIA_TYPES, STEP_ACTIONS } from '@utils/constants'
import { rejectionReasons } from '@cvg/content-review-localizations'

const reviewSteps = [
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Review Letter',
    captionShort: 'Letter',
    contentCardsConfig: [
      [
        {
          contentFilters: { mediaType: MEDIA_TYPES.text },
          selectable: true,
          validFlagReasons: [rejectionReasons.foulLanguage, rejectionReasons.outOfChannel]
        }
      ]
    ]
  },
  { action: STEP_ACTIONS.submit, captionLong: 'Submit', captionShort: 'Submit' }
]

export default { reviewSteps }
