import React from 'react'

import { Input } from '@cvg/react-components'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import { TRANSLATION_TYPES } from '@utils/constants'

import FlagContent from '../../../FlagContent'

import styles from './styles.module.css'

const Text = ({ contentItems, type }) => {
  const { currentStep, updateContent } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  const handleOnChange = ({ name, value }, currentTranslation) => {
    const eventTarget = {
      name,
      value: {
        translation: {
          ...currentTranslation,
          [type]: { ...currentTranslation[type], data: value }
        }
      }
    }

    updateContent(eventTarget)
  }

  return (
    <ul className={styles[type]}>
      {contentItems.map(contentItem => (
        <li key={contentItem.id}>
          <Input
            aria-labelledby={`${TRANSLATION_TYPES[type]}_language`}
            error={currentStep.errors[contentItem.id]}
            key={contentItem.id}
            name={`${TRANSLATION_TYPES[type]}_${contentItem.id}`}
            onChange={e => handleOnChange(e.target, contentItem.data.translation)}
            readOnly={contentItem.data.translation[type].readOnly}
            title={contentItem.data.translation[type].readOnly ? getTranslation('This value may not be edited') : ''}
            type='flexTextarea'
            value={contentItem.data.translation[type].data}
          />

          {type === TRANSLATION_TYPES.target && <FlagContent contentItem={contentItem} />}
        </li>
      ))}
    </ul>
  )
}

export default Text
