import { updateCurrentUser } from '@services/user'

import localize from '@cvg/localization-util'
import { localizations as contentReviewLocalizations } from '@cvg/content-review-localizations'

import logger from '@utils/logger'
import auth from '@services/auth'

import en from './en.json'
import es from './es.json'
import fr from './fr.json'

export const locales = {
  en: {
    name: 'English',
    values: { ...en, ...contentReviewLocalizations.en }
  },
  es: {
    name: 'Español',
    values: { ...es, ...contentReviewLocalizations.es }
  },
  fr: {
    name: 'Français',
    values: { ...fr, ...contentReviewLocalizations.fr }
  }
}

export const localizationOptions = {
  logErrorCallback: message => logger.error(message, { locales }),
  setIdCallback: languageId => {
    if (auth.getUser()) {
      auth.setUserLocale(languageId)
      updateCurrentUser({ locale: languageId })
    }
  }
}

export const getTranslationLanguages = content => {
  return {
    source: content?.contentItems.find(item => item.data.translation)?.data.translation.source.language,
    target: content?.contentItems.find(item => item.data.translation)?.data.translation.target.language
  }
}

export default localize(locales)

export const localizeArray = (arr, getTranslation) => {
  return new Map(arr.map(item => [item, getTranslation(item)]))
}
