export const translateText = async translation => {
  const url = import.meta.env.PUBLIC_GOOGLE_TRANSLATION_BASE_URI

  const params = new URLSearchParams({
    alt: 'json',
    key: import.meta.env.PUBLIC_GOOGLE_TRANSLATION_API_KEY,
    source: translation.source.language,
    format: 'text'
  })

  const body = {
    q: translation.source.data.map(({ text }) => text),
    target: translation.target.language
  }

  try {
    const response = await fetch(`${url}?${params}`, { method: 'POST', body: JSON.stringify(body) })
    const { data } = await response.json()

    return data.translations.map(({ translatedText }) => translatedText)
  } catch (error) {
    console.error('Translation error:', error)
    return translation.source.data.map(() => '')
  }
}
