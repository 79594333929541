import React from 'react'
import dayjs from 'dayjs'

import { STATS_DATE_MIN } from '@utils/constants'

import Stats from './Stats'
import DateFilter from '@components/Filters/DateFilter'

import useUserStats from '@hooks/useUserStats'

import styles from './styles.module.css'

const UserStats = ({ title, userId }) => {
  const { dates, onChange, stats, isError, isLoading } = useUserStats(userId)

  return (
    <div className={styles['user-stats']}>
      {title && <h2>{title}</h2>}

      <DateFilter dates={dates} onChange={onChange} min={STATS_DATE_MIN} max={dayjs().format('YYYY-MM-DD')} />
      <Stats stats={stats} isError={isError} isLoading={isLoading} />
    </div>
  )
}

export default UserStats
