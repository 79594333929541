import React from 'react'

import { useCustomContext } from '@cvg/react-hooks'

import { TRANSLATION_TYPES } from '@utils/constants'

import DeleteIcon from '@components/Icons/Delete'
import styles from './styles.module.css'

const DeleteSubtitle = ({ subtitleId, transcription }) => {
  const { updateContent } = useCustomContext()

  const removeSubtitle = () => {
    const data = transcription.data.translation?.source.data || transcription.data
    const updatedData = data.filter(data => data.id !== subtitleId)

    const eventTarget = {
      name: `${TRANSLATION_TYPES.source}_${transcription.id}`,
      value: transcription.data.translation
        ? {
            translation: {
              source: { ...transcription.data.translation.source, data: updatedData },
              target: { ...transcription.data.translation.target, data: [] }
            }
          }
        : updatedData
    }

    updateContent(eventTarget)
  }

  if (transcription.readOnly) return null

  return (
    <button className={styles.delete} onClick={removeSubtitle} type='button'>
      <DeleteIcon />
    </button>
  )
}

export default DeleteSubtitle
