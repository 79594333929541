import React from 'react'

import { Input } from '@cvg/react-components'
import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import { TRANSLATION_TYPES } from '@utils/constants'

import styles from './styles.module.css'

const Text = ({ subtitleId, text, transcription }) => {
  const { updateContent } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  const handleOnChange = ({ name, value }) => {
    const data = transcription.data.translation?.source.data || transcription.data
    const updatedData = data.map(data => (data.id === name ? { ...data, text: value } : data))

    const eventTarget = {
      name: `${TRANSLATION_TYPES.source}_${transcription.id}`,
      value: transcription.data.translation
        ? {
            translation: {
              source: { ...transcription.data.translation.source, data: updatedData },
              target: { ...transcription.data.translation.target, data: [] }
            }
          }
        : updatedData
    }

    updateContent(eventTarget)
  }

  return (
    <Input
      className={styles.text}
      label={getTranslation('Subtitle')}
      name={subtitleId}
      onChange={e => handleOnChange(e.target)}
      readOnly={transcription.readOnly}
      type='flexTextarea'
      value={text}
    />
  )
}

export default Text
