import React, { Fragment } from 'react'
import { useLanguageContext } from '@cvg/react-hooks'

import Page from '@components/Page'

import styles from './styles.module.css'

const descriptions = {
  moderatedOnly: 'STATS_MODERATED_ONLY',
  translated: 'STATS_TRANSLATED',
  translatedWithAssistance: 'STATS_TRANSLATED_WITH_ASSISTANCE',
  edited: 'STATS_EDITED'
}

const Stat = ({ caption, data }) => {
  const { getTranslation } = useLanguageContext()

  return (
    <div className={styles.data} key={caption}>
      <h3>{getTranslation(caption)}</h3>
      <dl>
        {Object.entries(data).map(([label, value]) => (
          <Fragment key={label}>
            <dt>
              <strong>{getTranslation(label)}</strong>
              {descriptions[label] && <small>{getTranslation(descriptions[label])}</small>}
            </dt>
            <dd>{value}</dd>
          </Fragment>
        ))}
      </dl>
    </div>
  )
}

const Stats = ({ stats, isError, isLoading }) => {
  return (
    <Page.AsyncContent isError={isError} isLoading={isLoading}>
      {Object.entries(stats).map(([caption, data]) => (
        <Stat caption={caption} data={data} key={caption} />
      ))}
    </Page.AsyncContent>
  )
}

export default Stats
