import { STEP_ACTIONS } from '@utils/constants'

export const getFlaggableContent = steps => {
  const flaggableContent = []

  steps
    .filter(step => step.action === STEP_ACTIONS.review)
    .forEach(step => {
      step.contentCards.flat().forEach(content => {
        const { validFlagReasons } = content.contentItems[0]

        const screening = content.screening.filter(
          item => item.rejectionReason && validFlagReasons.includes(item.rejectionReason)
        )

        if (!screening.length) return

        flaggableContent.push({ ...content, screening, step })
      }, [])
    })

  return flaggableContent
}

export const getSortedFlags = flags => [...flags].sort((a, b) => a.details?.position?.start - b.details?.position?.start)

export const isOverlappingPreviousFlag = (flags, newFlag) => flags.some(flag => isOverlap(flag, newFlag))

export const isOverlap = (previousFlag, newFlag) => {
  if (!newFlag.details) return false

  const overlapsStart =
    newFlag.details.position.start >= previousFlag.details.position.start &&
    newFlag.details.position.start < previousFlag.details.position.end

  const overlapsEnd =
    newFlag.details.position.end <= previousFlag.details.position.end &&
    newFlag.details.position.end > previousFlag.details.position.start

  const covers =
    newFlag.details.position.start <= previousFlag.details.position.start &&
    newFlag.details.position.end >= previousFlag.details.position.end

  return overlapsStart || overlapsEnd || covers
}
