import { MEDIA_TYPES, STEP_ACTIONS } from '@utils/constants'
import { rejectionReasons } from '@cvg/content-review-localizations'
import { VALIDATIONS } from '@utils/validations'

const { required } = VALIDATIONS

const reviewSteps = [
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Review Translation',
    captionShort: 'Translation',
    contentCardsConfig: [
      [
        {
          label: 'Video',
          contentFilters: { mediaType: MEDIA_TYPES.video },
          validFlagReasons: [
            rejectionReasons.inappropriateClothingForTheCulture,
            rejectionReasons.inappropriateVideo,
            rejectionReasons.poorVideoQuality
          ]
        },
        {
          label: 'Caption',
          contentFilters: { role: 'caption' },
          isOptional: true,
          validFlagReasons: [
            rejectionReasons.inappropriateContent,
            rejectionReasons.lastNames,
            rejectionReasons.personalContactInformation,
            rejectionReasons.socialMediaInformation
          ]
        }
      ],
      [
        {
          label: 'Translation',
          contentFilters: { role: 'translation' },
          validFlagReasons: [
            rejectionReasons.requestForSpecialFavorOrMoney,
            rejectionReasons.inappropriateContent,
            rejectionReasons.personalContactInformation,
            rejectionReasons.socialMediaInformation
          ],
          validations: [required]
        }
      ]
    ]
  },
  { action: STEP_ACTIONS.submit, captionLong: 'Submit', captionShort: 'Submit' }
]

export default { reviewSteps }
