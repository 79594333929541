import React, { useEffect, useState } from 'react'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import { translateText } from '@services/translate'
import { TRANSLATION_TYPES } from '@utils/constants'

import Tabs from '../Translation/Tabs'
import Text from './Text'
import Error from '@components/Error'

import styles from './styles.module.css'

const VideoTranslation = ({ content, languages }) => {
  const [tabView, setTabView] = useState(TRANSLATION_TYPES.source)

  const { getTranslation } = useLanguageContext()
  const { currentStep, updateContent } = useCustomContext()

  const hasError = content.contentItems.some(contentItem => Object.hasOwn(currentStep.errors, contentItem.id))

  useEffect(() => {
    const translateTargetLanguage = async () => {
      content.contentItems.forEach(async contentItem => {
        if (!contentItem.data.translation.target.data.length) {
          const translations = await translateText(contentItem.data.translation)

          const eventTarget = {
            name: `${TRANSLATION_TYPES.target}_${contentItem.id}`,
            value: {
              translation: {
                ...contentItem.data.translation,
                target: {
                  ...contentItem.data.translation.target,
                  data: contentItem.data.translation.source.data.map((item, idx) => ({
                    ...item,
                    text: translations[idx]
                  }))
                }
              }
            }
          }

          updateContent(eventTarget)
        }
      })
    }

    translateTargetLanguage()
  }, [])

  return (
    <div className={styles.translation} data-view={tabView}>
      <h3>{getTranslation(content.label)}</h3>
      <Tabs view={tabView} languages={languages} setView={setTabView} />
      <div className={styles.translations}>
        <Text contentItems={content.contentItems} type={TRANSLATION_TYPES.source} />
        <Text contentItems={content.contentItems} type={TRANSLATION_TYPES.target} />
      </div>

      {hasError && <Error error={getTranslation('TRANSLATION_ERROR')} />}
    </div>
  )
}

export default VideoTranslation
