import { isNotEmpty, isNumeric } from '@cvg/validators'

const isValidTranscription = ({ value }) => {
  const transcriptions = value.translation ? value.translation.source?.data : value

  if (!Array.isArray(transcriptions)) return false

  for (let i = 0; i < transcriptions.length; i++) {
    const prev = transcriptions[i - 1]
    const curr = transcriptions[i]

    if (prev?.endTime > curr.startTime || curr.startTime > curr.endTime) return false
  }

  return true
}

const isValidTranslation = ({ value }) => {
  if (!value?.translation) return true

  const getTranslationText = data => {
    return Array.isArray(data) ? data.map(item => item.text) : [data]
  }

  const source = getTranslationText(value.translation.source?.data)
  const target = getTranslationText(value.translation.target?.data)

  if (source.length !== target.length) return false

  return source.every((src, i) => (src.length && target[i]?.length) || (!src.length && !target[i]?.length))
}

export const VALIDATIONS = {
  currency: { validator: isNumeric, message: 'Must be in currency format' },
  required: { validator: isNotEmpty, message: 'Required field' },
  transcription: { validator: isValidTranscription, message: 'TRANSCRIPTION_ERROR' },
  translation: { validator: isValidTranslation, message: '' }
}

export const validateStep = step => {
  const errors = {}

  step.contentCards.flat().forEach(card => {
    card.contentItems.forEach(item => {
      item.validations.forEach(({ validator, message }) => {
        if (!validator({ contentItems: card.contentItems, value: item.data })) errors[item.id] = message
      })
    })
  })

  return errors
}
