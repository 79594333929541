import React from 'react'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import Subtitle from '../Subtitle'
import AddSubtitle from '../AddSubtitle'

import styles from './styles.module.css'

const Subtitles = ({ transcription, video }) => {
  const { currentStep } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  const error = currentStep.errors[transcription?.id]

  const subtitles = transcription.data.translation?.source?.data || transcription.data

  return (
    <>
      <div className={styles.subtitles} data-error={error}>
        {!subtitles.length && <p className={styles['no-subtitles']}>{getTranslation('No speech detected.')}</p>}

        {subtitles.map(subtitle => (
          <Subtitle key={subtitle.id} subtitle={subtitle} transcription={transcription} video={video} />
        ))}

        <AddSubtitle transcription={transcription} />
      </div>
      <div className={styles.error}>{getTranslation(error)}</div>
    </>
  )
}

export default Subtitles
