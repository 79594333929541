import { useState } from 'react'
import dayjs from 'dayjs'

import { useFetch } from '@cvg/react-hooks'

import { getUserStats } from '@services/user'
import { CACHE_KEYS } from '@utils/swr'

const useUserStats = userId => {
  const [dates, setDates] = useState({
    From: dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
    To: dayjs().format('YYYY-MM-DD')
  })

  const {
    data: stats = {},
    isError,
    isLoading
  } = useFetch(() => getUserStats(userId, dates.From, dates.To), {
    cacheKey: `${CACHE_KEYS.stats}/${userId}/${dates.From}/${dates.To}`
  })

  const onChange = e => setDates(prev => ({ ...prev, [e.target.name]: e.target.value }))

  return { dates, onChange, stats, isError, isLoading }
}

export default useUserStats
