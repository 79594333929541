import React from 'react'
import { useLanguageContext } from '@cvg/react-hooks'

import { localizeArray } from '@utils/localization'

import styles from './styles.module.css'

const FlagReasons = ({ reasons }) => {
  const { getTranslation } = useLanguageContext()

  return (
    <ul className={styles.reasons}>
      {[...localizeArray(reasons, getTranslation).values()]
        .sort((a, b) => a.localeCompare(b))
        .map((reason, idx) => (
          <li key={idx}>
            {/* This p supports containers / ellipsis */}
            <p>{reason}</p>
          </li>
        ))}
    </ul>
  )
}

export default FlagReasons
